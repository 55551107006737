import React from "react";
import Header from './components/Header';
import Footer from './components/Footer';
import casinoHotels from './data/casinoHotels';
import { Button } from '@headlessui/react';
import Contacts from './components/Contacts';

const HomePage = () => {
  return (
    <>
      <Header />
        <section className="text-center my-8">
        <img className="w-full max-h-[300px] object-cover mt-5 mb-10" src="/assets/img-12.jpeg" alt="hotels" />
<div className="container mx-auto px-4 my-10">
          <h1 className="text-4xl font-bold">Best Casino Hotels to Visit in Australia</h1>
          <p className="mt-4 text-lg">
            Explore an exclusive selection of Australia’s premier casino hotels, where luxury, entertainment, and top-tier amenities converge for an unforgettable experience.
          </p>
          
       <table className="w-full text-left table-auto border-collapse bg-slate-50 rounded my-10 shadow-md">
            <thead className='bg-slate-200'>
              <tr>
                <th className="p-4">Hotel Name</th>
                <th className="p-4">Accommodation Rating</th>
                <th className="p-4">Price, $</th>
                <th className="p-4">Availability</th>
              </tr>
            </thead>
            <tbody>
              {casinoHotels.map((hotel, index) => (
                <tr key={index} className="border-t">
                  <td className="px-4 py-2">{hotel.name}</td>
                  <td className="px-4 py-2">{hotel.rating} <svg className="inline-block" xmlns="http://www.w3.org/2000/svg" height="14px" width="14px" version="1.1" id="Capa_1" viewBox="0 0 47.94 47.94" >
<path fill="#ED8A19" d="M26.285,2.486l5.407,10.956c0.376,0.762,1.103,1.29,1.944,1.412l12.091,1.757  c2.118,0.308,2.963,2.91,1.431,4.403l-8.749,8.528c-0.608,0.593-0.886,1.448-0.742,2.285l2.065,12.042  c0.362,2.109-1.852,3.717-3.746,2.722l-10.814-5.685c-0.752-0.395-1.651-0.395-2.403,0l-10.814,5.685  c-1.894,0.996-4.108-0.613-3.746-2.722l2.065-12.042c0.144-0.837-0.134-1.692-0.742-2.285l-8.749-8.528  c-1.532-1.494-0.687-4.096,1.431-4.403l12.091-1.757c0.841-0.122,1.568-0.65,1.944-1.412l5.407-10.956  C22.602,0.567,25.338,0.567,26.285,2.486z"/>
</svg></td>
                  <td className="px-4 py-2">{hotel.price}</td>
                  <td className="px-4 py-2">
                    <a href={hotel.link} className="text-primary underline">Check Availability</a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Button as="a" href="/hotels" className="font-bold rounded bg-primary py-2 px-4 text-sm text-white data-[hover]:bg-sky-500 data-[hover]:data-[active]:bg-sky-700 transition-all ease-in-out duration-150">
            See More
          </Button>
          </div>
        </section>
        
        <div className="container mx-auto px-4 mt-24 mb-20">
        <section className="my-8">
          {infoBlocks.map((block, index) => (
            <div key={index} className="flex flex-col lg:flex-row items-center my-20 md:my-32">
              {index % 2 === 0 ? (
                <>
                  <img src={block.image} alt={block.title} className="w-full lg:w-1/2 h-80 object-cover" />
                  <div className="p-6 lg:w-1/2">
                    <h3 className="text-3xl font-semibold mb-4">{block.title}</h3>
                    <p className="text-gray-700 text-md">{block.description}</p>
                  </div>
                </>
              ) : (
                <>
                  <div className="p-6 lg:w-1/2">
                    <h3 className="text-3xl font-semibold mb-4">{block.title}</h3>
                    <p className="text-gray-700 text-md">{block.description}</p>
                  </div>
                  <img src={block.image} alt={block.title} className="w-full lg:w-1/2 h-80 object-cover" />
                </>
              )}
            </div>
          ))}
        </section>

        <section className="my-8">
          <div className="flex flex-col lg:flex-row items-center my-20 md:my-32">
            <img src="/assets/img-9.webp" alt="Casino Experience" className="w-full lg:w-1/2 h-80 object-cover" />
            <div className="p-6 lg:w-1/2">
              <h3 className="text-2xl font-semibold mb-4">What to Do at Casino Hotels</h3>
              <p className="text-gray-600">
                Casino hotels offer a plethora of activities beyond the gaming floor. Guests can enjoy world-class dining experiences, relax at luxurious spas, or take a dip in expansive pools. Many casino hotels also feature live entertainment, from musical performances to theatrical shows, ensuring there is never a dull moment. For those looking to stay active, fitness centers and sports facilities are often available. Additionally, high-end shopping boutiques and vibrant nightlife options make casino hotels a complete entertainment destination.
              </p>
            </div>
          </div>

          <div className="flex flex-col lg:flex-row items-center my-20">
            <div className="p-6 lg:w-1/2">
              <h3 className="text-2xl font-semibold mb-4">Benefits of Staying at Casino Hotels</h3>
              <p className="text-gray-600">
                Staying at a casino hotel comes with numerous benefits. These establishments offer a unique combination of luxurious accommodations and exciting entertainment options. Guests can enjoy the convenience of having top-notch restaurants, bars, and live entertainment all within the same property. The gaming facilities are world-class, providing an array of options for both novice and experienced gamblers. Additionally, the overall ambiance of casino hotels is vibrant and dynamic, making them perfect for a lively and memorable stay. Many casino hotels also provide exclusive VIP services and packages, ensuring a premium experience for all guests.
              </p>
            </div>
            <img src="/assets/img-10.avif" alt="Benefits of Casino Hotels" className="w-full lg:w-1/2 h-80 object-cover" />
          </div>
        </section>
      </div>
      <Contacts />
      <Footer />
    </>
  );
};

const infoBlocks = [
  {
    title: 'Crown Towers Melbourne',
    description: 'Crown Towers Melbourne offers unparalleled luxury with its opulent rooms, exceptional dining options, and a vast array of entertainment choices. Guests can enjoy the expansive casino floor, multiple fine-dining restaurants, and world-class spa facilities. This iconic hotel provides breathtaking views of the city skyline and easy access to Melbourne’s top attractions. The dedicated staff ensures that every stay is comfortable and memorable, making it a preferred choice for discerning travelers.',
    image: '/assets/img-1.webp'
  },
  {
    title: 'The Star Sydney',
    description: 'Located in the heart of Sydney, The Star offers a unique blend of luxury accommodations, diverse dining experiences, and vibrant entertainment options. The hotel features elegantly designed rooms with stunning views of Darling Harbour, a state-of-the-art casino, and an array of bars and restaurants. Guests can indulge in the luxurious spa, attend live performances, or simply relax by the rooftop pool. The Star Sydney is the perfect destination for those seeking excitement and sophistication.',
    image: '/assets/img-6.jpg'
  },
  {
    title: 'Jupiters Hotel & Casino',
    description: 'Situated on the Gold Coast, Jupiters Hotel & Casino provides a perfect combination of relaxation and excitement. The hotel boasts luxurious rooms with modern amenities, a variety of dining options, and a lively casino floor. Guests can enjoy the outdoor pool, spa services, and nightly entertainment. With its prime location near the beach and major attractions, Jupiters is an ideal choice for both leisure and business travelers looking for a high-energy getaway.',
    image: '/assets/img-7.png'
  },
  {
    title: 'Crown Perth',
    description: 'Crown Perth is a premier destination for luxury and entertainment in Western Australia. The hotel offers stylish accommodations, gourmet dining, and an extensive casino with a wide range of gaming options. Guests can relax in the luxurious spa, take a dip in the outdoor pool, or explore the nearby cultural attractions. With its exceptional service and elegant surroundings, Crown Perth promises an unforgettable stay for all its guests.',
    image: '/assets/img-8.jpg'
  }
];

export default HomePage;
