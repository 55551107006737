

const casinoHotels = [
  {
    name: 'Crown Towers Melbourne',
    description: 'Crown Towers Melbourne offers unparalleled luxury with its opulent rooms, exceptional dining options, and a vast array of entertainment choices.',
    image: '/assets/img-2.webp',
    link: 'https://www.crownmelbourne.com.au/',
    rating: '9.4',
    reviews: '1500',
    price: '$300 per night',
    address: '8 Whiteman St, Southbank VIC 3006, Australia',
    reviewDate: '5 Jun 2024'
  },
  {
    name: 'The Star Sydney',
    description: 'Located in the heart of Sydney, The Star offers a unique blend of luxury accommodations, diverse dining experiences, and vibrant entertainment options.',
    image: '/assets/img-3.avif',
    link: 'https://www.star.com.au/sydney/',
    rating: '9.2',
    reviews: '2000',
    price: '$280 per night',
    address: '80 Pyrmont St, Pyrmont NSW 2009, Australia',
    reviewDate: '5 Jun 2024'
  },
  {
    name: 'Jupiters Hotel & Casino Gold Coast',
    description: 'Jupiters Hotel & Casino provides the ultimate Gold Coast experience with its premium accommodations, diverse dining options, and vibrant casino floor.',
    image: '/assets/img-4.webp',
    link: 'https://www.star.com.au/goldcoast/',
    rating: '8.8',
    reviews: '1200',
    price: '$250 per night',
    address: 'Broadbeach Island, Broadbeach QLD 4218, Australia',
    reviewDate: '5 Jun 2024'
  },
  {
    name: 'Crown Perth',
    description: 'Crown Perth combines luxury and excitement with its spacious rooms, exquisite restaurants, and expansive casino facilities.',
    image: '/assets/img-11.jpeg',
    link: 'https://www.crownperth.com.au/',
    rating: '9.0',
    reviews: '1100',
    price: '$270 per night',
    address: 'Great Eastern Hwy, Burswood WA 6100, Australia',
    reviewDate: '5 Jun 2024'
  },
  {
    name: 'The Star Gold Coast',
    description: 'The Star Gold Coast offers an unparalleled experience with its luxury accommodations, diverse dining options, and a state-of-the-art casino.',
    image: '/assets/img-10.avif',
    link: 'https://www.star.com.au/goldcoast/',
    rating: '9.1',
    reviews: '1600',
    price: '$290 per night',
    address: 'Broadbeach Island, Broadbeach QLD 4218, Australia',
    reviewDate: '5 Jun 2024'
  },
  {
    name: 'SkyCity Adelaide',
    description: 'SkyCity Adelaide is a premier destination offering luxury accommodations, fine dining, and an exciting casino experience in a historic setting.',
    image: '/assets/img-9.webp',
    link: 'https://www.skycityadelaide.com.au/',
    rating: '8.7',
    reviews: '900',
    price: '$220 per night',
    address: 'North Terrace, Adelaide SA 5000, Australia',
    reviewDate: '5 Jun 2024'
  }
];

  export default casinoHotels;
  